import { put, call, fork, takeEvery, select } from 'redux-saga/effects'
import ApiController from 'domain/controllers/Api.controller';
import * as types from 'domain/types/restaurants.type';
import * as actions from "domain/actions/restaurants.action";
import * as constants from "domain/constants/restaurants.constant";
import * as reducers from "domain/reducers/restaurants.reduce";
import { Result } from 'domain/types/static.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { ICity } from 'domain/types/city.type';
import { getFiltersRestaurant } from 'domain/reducers/restaurants.reduce';

const api = new ApiController();
const norr = new NorrController();

interface IFilters {
  categories: types.ICategory[];
  cities: ICity[];
};

export interface IPagination {
  page: number;
  pageSize: number;
}

export async function getRestaurantsFetch( filters: IFilters ): Promise<Result<types.IRestaurant[]>> {
  return await api.get(`/restaurants`, { filters })
}

export async function getRestaurantsFilterFetch(filters: IFilters, pagination: IPagination): Promise<Result<Array<types.IRestaurant>>> {
  return await api.get(`/restaurants/filter`, { filters, pagination })
}

export async function getRestaurantByIdFetch(id: string): Promise<Result<types.IRestaurant>> {
  return await api.get(`/restaurants/${id}`)
}

export async function getManagerByRestaurantFetch(id:string): Promise<Result<types.IRestaurant>> {
  return await api.get(`/manager/bind/restaurant/${id}`)
}

export async function getBidsMyFetch(): Promise<Result<Array<types.IRestaurantBidMy>>> {
  return await api.get(`/restaurants/bid/my`)
}

export async function getBidsHelpsFetch(): Promise<Result<any[]>> {
  return await api.get(`/restaurants/help/my`)
}

export async function getBidsUpdateFetch(): Promise<Result<Array<any>>> {
  return await api.get(`/restaurants/bid/update`)
}

export async function getActiveTasksFetch(id: string): Promise<Result<Array<any>>> {
  return await api.get(`/restaurants/${id}/is_active/tasks`)
}

export async function postRestaurantFetch(data: types.IPostRestaurant): Promise<Result<any>> {
  return await api.post(`/restaurants`, data)
}

export async function putRestaurantFetch(restaurantId: string, data: types.IPutRestaurant): Promise<Result<any>> {
  return await api.put(`/restaurants/${restaurantId}/update`, data)
}

export async function uploadLogotypeFetch(file: File): Promise<Result<string>> {
  const fd = new FormData();
  fd.append('image', file, file.name)
  return await api.post(`/restaurants/upload/logotype`, fd)
}

export async function uploadGalleryRestaurantFetch(restaurantId: string, gallery: Array<File>): Promise<Result<any>>{
  const fd = new FormData();
  gallery.forEach( (image: any) => {
    fd.append('gallery', image, image.name)
  })
  return await api.post(`/restaurants/${restaurantId}/upload/gallery`, fd)
}

export async function uploadGalleryRestaurantUpdateFetch(restaurantId: string, gallery: Array<File>): Promise<Result<any>> {
  const fd = new FormData();
  gallery.forEach( (image: any) => {
    fd.append('gallery', image, image.name)
  })
  return await api.post(`/restaurants/${restaurantId}/update/gallery`, fd)
}

export async function getBidUpdateByIdFetch(bidId:string): Promise<Result<types.IRestaurantBidUpdate>> {
  return await api.get(`/restaurants/bid/update/${bidId}`)
}

export async function completeBidFetch(bidId: string): Promise<Result<any>> {
  return await api.put(`/restaurants/bid/${bidId}/complete`, {})
}

export async function denyBidFetch(bidId: string): Promise<Result<any>> {
  return await api.put(`/restaurants/bid/${bidId}/deny`, {})
}

export async function deleteRestaurantFetch(restaurantId: string): Promise<Result<any>> {
  return await api.delete(`/restaurants/${restaurantId}/delete`)
}

export async function deleteBidFetch(bidId: string): Promise<Result<any>> {
  return await api.put(`/restaurants/bid/${bidId}/delete`, {})
}

export async function completeBidUpdateFetch(bidId: string): Promise<Result<any>> {
  return await api.put(`/restaurants/bid/update/${bidId}/complete`, {})
}

export async function denyBidUpdateFetch(bidId: string): Promise<Result<any>> {
  return await api.put(`/restaurants/bid/update/${bidId}/deny`, {})
}

export async function bindOwnerFetch(restaurantId: string, ownerId: string): Promise<Result<types.IRestaurant>> {
  return await api.post(`/restaurants/${restaurantId}/bind`, {ownerId})
}

export async function setActiveFetch(restaurantId: string): Promise<Result<types.IRestaurant>> {
  return await api.put(`/restaurants/${restaurantId}/active`, {})
}

export async function setDeactivateFetch(restaurantId: string): Promise<Result<types.IRestaurant>> {
  return await api.put(`/restaurants/${restaurantId}/deactivate`, {})
}

export async function getCategoriesDishesFetch(): Promise<Result<Array<types.ICategory>>> {
  return await api.get(`/kitchen/category`)
}

export async function getTagsDishesFetch(): Promise<Result<Array<types.ICategory>>> {
  return await api.get(`/dishes/tags`)
}

export async function getCategoriesHookahFetch(): Promise<Result<Array<types.ICategory>>> {
  return await api.get(`/hookah/category`)
}

export async function postCategoriesDishesFetch(data: types.ICategory): Promise<Result<Array<types.ICategory>>> {
  return await api.post(`/kitchen/category`, data)
}

export async function putCategoriesDishesFetch(data: types.ICategory): Promise<Result<Array<types.ICategory>>> {
  return await api.put(`/kitchen/category/${data.id}`, data)
}

export async function putCategoriesHookahFetch(data: types.ICategory): Promise<Result<Array<types.ICategory>>> {
  return await api.put(`/hookah/category/${data.id}`, data)
}

export async function getKitchensFetch(): Promise<Result<Array<types.ICategory>>> {
  return await api.get(`/kitchen/national`)
}

export async function getCategoriesFetch(): Promise<Result<Array<types.ICategory>>> {
  return await api.get(`/category/restaurants`)
}

export async function postKitchensFetch(data: types.ICategory): Promise<Result<any>> {
  return await api.post(`/kitchen/national`, data)
}

export async function putOrderDishesFetch(data: types.IDishes[]):Promise<Result<any>> {
  return await api.put(`/kitchen/dishes/orders`, data)
}

export async function putOrderHookahFetch(data: types.IHookah[]):Promise<Result<any>> {
  console.log(data)
  return await api.put(`/hookah/orders`, data)
}

export async function putKitchensFetch(data: types.ICategory): Promise<Result<any>> {
  return await api.put(`/kitchen/national/${data.id}`, data)
}

export async function getDishesByRestaurantFetch(restaurantId: string): Promise<Result<Array<types.IDishes>>>{
  return await api.get(`/kitchen/dishes/restaurant/${restaurantId}`)
}

export async function getHookahByRestaurantFetch(restaurantId: string): Promise<Result<Array<types.IDishes>>>{
  return await api.get(`/hookah/restaurant/${restaurantId}`)
}

export async function postDishesFetch(data: types.IDishes, restaurantId: string): Promise<Result<types.IDishes>> {
  return await api.post(`/kitchen/dishes/restaurant/${restaurantId}`, data)
}

export async function copyDishesFetch(data: {copyRestaurantId: string}, restaurantId: string): Promise<Result<types.IDishes>> {
  console.log(data)
  return await api.post(`/kitchen/dishes/copy/restaurant/${restaurantId}`, data)
}

export async function postHookahFetch(data: types.IHookah, restaurantId: string): Promise<Result<any>> {
  return await api.post(`/hookah/restaurant/${restaurantId}`, data)
}

export async function putDishesFetch(dishesId: string, data: types.IDishes): Promise<Result<types.IDishes>> {
  return await api.put(`/kitchen/dishes/${dishesId}`, data)
}

export async function deleteDishesFetch(dishesId: string, restaurantId: string): Promise<Result<void>> {
  return await api.delete(`/kitchen/dishes/${dishesId}/restaurant/${restaurantId}`)
}

export async function deleteCategoryNationalFetch(categoryId: string): Promise<Result<void>> {
  return await api.delete(`/kitchen/national/${categoryId}`)
}

export async function deleteCategoryDishesFetch(categoryId: string): Promise<Result<void>> {
  return await api.delete(`/kitchen/category/${categoryId}`)
}

export async function putHookahFetch(hookahId: string, data: types.IHookah): Promise<Result<types.IHookah>> {
  return await api.put(`/hookah/${hookahId}`, data)
}

export async function deleteHookahFetch(hookahId: string): Promise<Result<void>> {
  return await api.delete(`/hookah/${hookahId}`)
}

export async function setCoordinateFetch(data: any): Promise<Result<void>> {
  return await api.post(`/restaurants/coordinate`, data);
}

export async function updateCoordinateFetch(id: string, data: any): Promise<Result<void>> {
  return await api.put(`/restaurants/coordinate/${id}`, data);
}

export async function uploadDishesFetch(file: any): Promise<Result<string>> {
  const fd = new FormData();
  fd.append('image', file, file.name)
  return await api.post(`/kitchen/dishes/photo`, fd)
}

export async function uploadHookahFetch(file: any): Promise<Result<string>> {
  const fd = new FormData();
  fd.append('image', file, file.name)
  return await api.post(`/hookah/photo`, fd)
}

export async function postCategoriesHookahFetch(data: types.ICategory): Promise<Result<Array<types.ICategory>>> {
  return await api.post(`/hookah/category`, data)
}

export async function postYoutubeVideoFetch(data: types.IYoutubeVideo): Promise<Result<Result<void>>> {
  return await api.post(`/restaurants/youtube`, data)
}

export async function postRutubeVideoFetch(data: types.IYoutubeVideo): Promise<Result<Result<void>>> {
  return await api.post(`/restaurants/rutube`, data)
}

export async function deleteRutubeVideoFetch(id: string): Promise<Result<Result<void>>> {
  return await api.delete(`/restaurants/rutube/${id}`)
}

export async function postVideoFetch(data: types.IVideo): Promise<Result<Result<void>>> {
  return await api.post(`/restaurants/video`, data)
}

export async function deleteVideoFetch(id: string): Promise<Result<Result<void>>> {
  return await api.delete(`/restaurants/video/${id}`)
}

export async function updateFullnessFetch(id: string, data: any): Promise<Result<Result<void>>> {
  return await api.put(`/restaurants/${id}/fullness`, data);
}

export async function getAverageReceiptFetch(restaurantId: string): Promise<Result<void>> {
  return await api.get(`/restaurants/${restaurantId}/average`);
}

export async function uploadYoutubeFileFetch(file: any): Promise<Result<string>> {
  const fd = new FormData();
  fd.append('image', file, file.name)
  return await api.post(`/restaurants/youtube/file`, fd)
}

export async function uploadXlsFetch(file: any): Promise<Result<any>> {
  const fd = new FormData();
  fd.append('xls', file, file.name)
  return await api.post(`/parser/xls`, fd) 
}

export async function uploadXlsDishFetch(file: any): Promise<Result<any>> {
  const fd = new FormData();
  fd.append('xls', file, file.name)
  return await api.post(`/parser/xls/dishes`, fd) 
}

export function* getRestaurants(): any{
  yield put(actions.reqRestaurants(true))

  const { page, pageSize } = yield select(reducers.getPaginationRestaurant);
  const filterReduce = yield select(getFiltersRestaurant);

  const filters = {
    cities: filterReduce.cities.map( (city: ICity) => city.id ),
    categories: filterReduce.categories.map( (category: types.ICategory) => category.id ),
    status: filterReduce.status,
    like: filterReduce.like,
    fullness: filterReduce.isFullness
  }

  const pagination = {
    page: page,
    pageSize: pageSize
  }

  const response = yield call(getRestaurantsFilterFetch, filters, pagination);
  yield call(norr.processing, response, function *(){
    yield put(actions.setAllCountRestaurants(response.value.count))
    
    const restaurants = response.value.rows;
    yield put(actions.setRestaurants(restaurants))
  })

  yield put(actions.reqRestaurants(false))
}

export function* getRestaurantById(action: any): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(getRestaurantByIdFetch, action.payload.restaurantId);
  if(response.type === "success"){
    yield put(actions.setCurrentRestaurant(response.value))
  }else {
    console.log(response, "error restaurant id")
  }

  yield put(actions.reqRestaurants(false))
}

export function* getManagerByRestaurant(action: any): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(getManagerByRestaurantFetch, action.payload.restaurantId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setManagerRestaurant(response.value))
  })

  yield put(actions.reqRestaurants(false))
}

export function* deleteRestaurant(): any{
  const restaurant = yield select(reducers.getRestaurant);

  const response = yield call(deleteRestaurantFetch, restaurant.id);
  yield call(norr.processing, response, function *(){
    yield call(getRestaurants)
  }, "Заведение удалено")
}

export function* showCurrentWin(action: any): any{
  const restaurantId = action.payload.restaurantId;
  const window = action.payload.typeWindow

  const response = yield call(getRestaurantByIdFetch, restaurantId);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCurrentRestaurant(response.value))
    yield put(actions.showWindow(window, true))
  })
}

export function* getBidsMy(): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(getBidsMyFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setBidsMy(response.value))
  })

  yield put(actions.reqRestaurants(false))
}

export function* getBidsHelps(): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(getBidsHelpsFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setBidsHelps(response.value))
  })

  yield put(actions.reqRestaurants(false))
}

export function* getBidsUpdate(): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(getBidsUpdateFetch);
  yield call(norr.processing, response, function *(){
    const result = response.value.map( (item: any) => {
      item.bid.main = JSON.parse(item.bid.main);
      item.bid.addInfo = JSON.parse(item.bid.addInfo);
      item.bid.social = JSON.parse(item.bid.social);
      item.bid.working = JSON.parse(item.bid.working);
      item.bid.gallery = JSON.parse(item.bid.gallery);
      item.bid.hashtags = JSON.parse(item.bid.hashtags);
      
      return item;
    })

    yield put(actions.setBidsUpdate(result))
  })

  yield put(actions.reqRestaurants(false))
}

export function* getBidUpdateById(action: any): any{
  const response = yield call(getBidUpdateByIdFetch, action.payload.bidId);
  yield call(norr.processing, response, function *(){
    
    response.value.main = JSON.parse(response.value.main);
    response.value.addInfo = JSON.parse(response.value.addInfo);
    response.value.social = JSON.parse(response.value.social);
    response.value.working = JSON.parse(response.value.working);
    response.value.gallery = JSON.parse(response.value.gallery);
    response.value.hashtags = JSON.parse(response.value.hashtags);
    
    yield put(actions.setCurrentBidUpdate(response.value))
  })
}

export function* completeBid(action: any): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(completeBidFetch, action.payload.bidId);
  if(response.type === "success"){
    yield call(getBidsMy)
  }else {
    console.log(response, "error bids my id")
  }

  yield put(actions.reqRestaurants(false))
}

export function* denyBid(action: any): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(denyBidFetch, action.payload.bidId);
  if(response.type === "success"){
    yield call(getBidsMy)
  }else {
    console.log(response, "error bids my id")
  }

  yield put(actions.reqRestaurants(false))
}

export function* deleteBid(action: any): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(deleteBidFetch, action.payload.bidId);
  if(response.type === "success"){
    yield call(getBidsMy)
  }else {
    console.log(response, "error bids my id")
  }

  yield put(actions.reqRestaurants(false))
}

export function* completeBidUpdate(): any{
  const update = yield select(reducers.getUpdateRestaurant)
  const response = yield call(completeBidUpdateFetch, update.id);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow("bid_update", false))
  }, "Заявка выполнена")
}

export function* denyBidUpdate(): any{
  const update = yield select(reducers.getUpdateRestaurant)
  const response = yield call(denyBidUpdateFetch, update.id);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow("bid_update", false))
    yield call(getBidsUpdate)
  }, "Заявка удалена")
}

export function* bindOwner(action: any): any{
  const ownerId = action.payload.ownerId
  const restaurant = yield select(reducers.getRestaurant)

  const response = yield call(bindOwnerFetch, restaurant.id, ownerId);
  if(response.type === "success") {
    yield put(actions.showWindow('bind_owner', false))
    yield call(getRestaurants)
  }
  if(response.type === "error") console.log(response, "error bind owner")
}

export function* setActiveRestaurant(action: any): any{
  const { restaurantId, isActive } = action.payload;
  if(isActive){
    const response = yield call(setActiveFetch, restaurantId);

    yield call(norr.processing, response, function *(){
      yield call(getRestaurants)
    })
  }else {
    const response = yield call(setDeactivateFetch, restaurantId);
    yield call(norr.processing, response, function *(){
      yield call(getRestaurants)
    })
  }
}

export function* postRestaurant(action: any): any{
  let data = action.payload.data;
  const gallery = data.gallery;
  const logotype = data.logotype;

  let logo = "";
  const uploadLogo = yield call(uploadLogotypeFetch, logotype)
  const resultLogo = yield call(norr.processing, uploadLogo, function(){
    logo = uploadLogo.value
  })

  if(!resultLogo) return ;

  data.logo = logo;

  const response = yield call(postRestaurantFetch, data)
  const result = yield call(norr.processing, response, function *(){})
  
  if(result){
    const resUpload = yield call(uploadGalleryRestaurantFetch, response.value, gallery)

    yield call(norr.processing, resUpload, function *(){
      yield put(actions.showWindow('add', false))
      yield call(getRestaurants)
    }, "Новое заведение создано")
  } 
}

export function* checkActiveTasks() {

}

export function* putRestaurant(action: any): any{
  const data = action.payload.data;
  const gallery = data.newGallery;
  const logotype = data.logotype;
  const isCheck = action.payload.isCheck;
  const restaurant = yield select(reducers.getRestaurant)

  if(
    (isCheck !== false) && 
    (data.addInfo.averageReceipt !== restaurant.addInfo.averageReceipt)
  ){
    const checkActiveTasks = yield call(getActiveTasksFetch, restaurant.id)
    yield call(norr.processing, checkActiveTasks, function *(){})

    if(checkActiveTasks.value) return yield put(actions.showWindow('warningActiveTask', true))
    if(checkActiveTasks.type === 'error') return ;
  }

  if(logotype){
    let logo = "";
    const uploadLogo = yield call(uploadLogotypeFetch, logotype)
    const resultLogo = yield call(norr.processing, uploadLogo, function(){
      logo = uploadLogo.value
    })
    
    if(!resultLogo) return ;

    data.main.logo = logo;
  }

  const response = yield call(putRestaurantFetch, restaurant.id, data)
  const updateRestaurant = yield call(norr.processing, response, function *(){})

  if(updateRestaurant) {
    const resUpload = yield call(uploadGalleryRestaurantUpdateFetch, restaurant.id, gallery)

    yield call(norr.processing, resUpload, function *(){
      yield put(actions.showWindow('update', false))
      yield call(getRestaurants)
    }, "Заведение изменено")
  }
}

export function* getCategoriesDishes(): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(getCategoriesDishesFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCategoriesDishes(response.value))
  })

  yield put(actions.reqRestaurants(false))
}

export function* getTagsDishes(): any{
  yield put(actions.reqRestaurants(true))

  const response = yield call(getTagsDishesFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setTagsDishes(response.value))
  })

  yield put(actions.reqRestaurants(false))
}

export function* getCategoriesHookah(): any{
  const response = yield call(getCategoriesHookahFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCategoriesHookah(response.value))
  })
}

export function* postCategoriesDishes(action: any): any{
  const category = action.payload.category;

  const response = yield call(postCategoriesDishesFetch, category);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('addCategory', false))
    yield call(getCategoriesDishes)
  })
}

export function* postCategoriesHookah(action: any): any{
  const category = action.payload.category;

  const response = yield call(postCategoriesHookahFetch, category);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('addCategoryHookah', false))
    yield call(getCategoriesHookah)
  })
}

export function* postYoutubeVideo(action: any): any{
  const data = action.payload;

  const linkImg = yield call(uploadYoutubeFileFetch, data.file);
  if(linkImg.type !== 'success') return;

  data.link = linkImg.value

  const response = yield call(postYoutubeVideoFetch, data);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('list_youtube', false))
    yield call(getRestaurants)
  })
}

export function* postRutubeVideo(action: any): any{
  const data = action.payload;

  const response = yield call(postRutubeVideoFetch, data);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('add_video_rutube', false))
    yield call(getRestaurants)
    yield call(getRestaurantById, {payload: {restaurantId: data.restaurantId}})
  })
}

export function* deleteRutubeVideo(action: any): any{
  const data = action.payload;
  const restaurant = yield select(reducers.getRestaurant);

  const response = yield call(deleteRutubeVideoFetch, data.id);
  yield call(norr.processing, response, function *(){
    yield call(getRestaurants)
    yield call(getRestaurantById, {payload: {restaurantId: restaurant.id}})
  })
}

export function* postVideo(action: any): any{
  const data = action.payload;

  const response = yield call(postVideoFetch, data);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('add_video', false))
    yield call(getRestaurants)
    yield call(getRestaurantById, {payload: {restaurantId: data.restaurantId}})
  })
}

export function* deleteVideo(action: any): any{
  const data = action.payload;
  const restaurant = yield select(reducers.getRestaurant);

  const response = yield call(deleteVideoFetch, data.id);
  yield call(norr.processing, response, function *(){
    yield call(getRestaurants)
    yield call(getRestaurantById, {payload: {restaurantId: restaurant.id}})
  })
}

export function* putCategoriesDishes(action: any): any{
  const category = action.payload.category;

  const response = yield call(putCategoriesDishesFetch, category);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('editCategory', false))
    yield call(getCategoriesDishes)
  })
}

export function* putCategoriesHookah(action: any): any{
  const category = action.payload.category;

  const response = yield call(putCategoriesHookahFetch, category);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('editCategoryHookah', false))
    yield call(getCategoriesHookah)
  })
}

export function* getCategories(): any{
  const response = yield call(getCategoriesFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCategories(response.value))
  })
}

export function* getKitchens(): any {
  const response = yield call(getKitchensFetch);
  yield call(norr.processing, response, function *(){
    yield put(actions.setKitchens(response.value))
  })
}

export function* setOrderDishes(action:any): any {
  const dishes = action.payload.dishes;
  const cb = action.payload.cb;

  const response = yield call(putOrderDishesFetch, dishes);
  yield call(norr.processing, response, function *(){
    yield call(getKitchens)
    cb()
  })
}

export function* setOrderHookah(action:any): any {
  const hookah = action.payload.hookah;
  const cb = action.payload.cb;

  const response = yield call(putOrderHookahFetch, hookah);
  yield call(norr.processing, response, function *(){
    yield cb()
  })
}

export function* postKitchens(action: any): any{
  const kitchen = action.payload.kitchen;

  const response = yield call(postKitchensFetch, kitchen);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('addKitchen', false))
    yield call(getKitchens)
  })
}

export function* putKitchens(action: any): any{
  const kitchen = action.payload.kitchen;

  const response = yield call(putKitchensFetch, kitchen);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('editKitchen', false))
    yield call(getKitchens)
  })
}

export function* getDishesByRestaurant(): any {
  yield put(actions.reqRestaurants(true))

  const restaurant = yield select(reducers.getRestaurant);

  const response = yield call(getDishesByRestaurantFetch, restaurant.id);
  yield call(norr.processing, response, function *(){
    yield put(actions.setDishes(response.value))
  })

  yield put(actions.reqRestaurants(false))
}

export function* getHookahByRestaurant(): any {
  const restaurant = yield select(reducers.getRestaurant);

  const response = yield call(getHookahByRestaurantFetch, restaurant.id);
  yield call(norr.processing, response, function *(){
    yield put(actions.setHookah(response.value))
  })

}

export function* postHookah(action: any): any{
  const hookah = action.payload.hookah;
  const file = action.payload.file;

  const restaurant = yield select(reducers.getRestaurant);
  const photo = file ? yield call(uploadHookahFetch, file) : null;

  hookah.categoryId = hookah.categoryId?.id;
  hookah.photo = photo && (photo.type === 'success') ? photo.value : ""
  
  const response = yield call(postHookahFetch, hookah, restaurant.id);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('addHookah', false))
    yield call(getRestaurantById, {payload: {restaurantId: restaurant.id}})
  })
}

export function* putHookah(action: any): any{
  const hookah = action.payload.hookah;
  const file = action.payload.file;
  const restaurant = yield select(reducers.getRestaurant);

  if(file){
    const photo = yield call(uploadHookahFetch, file);
    hookah.photo = photo.type === 'success' ? photo.value : hookah.photo
  }
  
  hookah.categoryId = hookah.categoryId?.id;
  
  const response = yield call(putHookahFetch, hookah.id, hookah);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('editHookah', false))
    yield call(getRestaurantById, {payload: {restaurantId: restaurant.id}})
  })
}

export function* deleteHookah(action: any):any{
  const hookahId = action.payload.hookahId;
  const restaurant = yield select(reducers.getRestaurant);

  const response = yield call(deleteHookahFetch, hookahId);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('editHookah', false))
    yield call(getRestaurantById, {payload: {restaurantId: restaurant.id}})
  })
}

export function* setCoordinate(action: any): any{
  const coordinate = action.payload;
  const restaurant = yield select(reducers.getRestaurant);

  let response = null;

  if(restaurant?.coordinate){
    response = yield call(updateCoordinateFetch, restaurant.id, { ...coordinate } );
  }else {
    response = yield call(setCoordinateFetch, { restaurantId: restaurant.id, ...coordinate } );
  }
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('coordinate', false))
    yield call(getRestaurantById, {payload: {restaurantId: restaurant.id}})
  })
  
}

export function* postDishes(action: any): any{
  const dishes = action.payload.dishes;
  const file = action.payload.file;

  const restaurant = yield select(reducers.getRestaurant);
  const photo = file ? yield call(uploadDishesFetch, file) : null;

  dishes.categoryId = dishes.categoryId?.id;
  dishes.kitchenId = dishes.kitchenId?.id;
  dishes.tagIds = dishes?.tagIds ? dishes.tagIds.map((tag: { id: string; }) => tag.id) : [];
  dishes.photo = photo && (photo.type === 'success') ? photo.value : ""
  
  const response = yield call(postDishesFetch, dishes, restaurant.id);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('addDishes', false))
    yield call(getDishesByRestaurant)
  })
}

export function* copyDishes(action: any): any{
  const restaurant = yield select(reducers.getRestaurant);
  const copyRestaurantId = action.payload.copyRestaurantId;
  
  const response = yield call(copyDishesFetch, { copyRestaurantId }, restaurant.id);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('copyDishes', false))
    yield call(getDishesByRestaurant)
  })
}

export function* putDishes(action: any): any{
  const dishes = action.payload.dishes;
  
  const file = action.payload.file;
  const isClear = action.payload.clear;

  if(file){
    const photo = yield call(uploadDishesFetch, file);
    dishes.photo = photo.type === 'success' 
      ? photo.value 
      : dishes.photo
  }else if(isClear) {
    dishes.photo = ''
  }
  const response = yield call(
    putDishesFetch, 
    dishes.id, 
    {
      ...dishes, 
      categoryId: dishes.categoryId?.id,
      kitchenId:  dishes.kitchenId?.id,
      tagIds: dishes?.tagIds ? dishes.tagIds.map((tag: { id: string; }) => tag.id) : [],
      order: String(dishes.order)
    }
  );
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('editDishes', false))
    yield call(getDishesByRestaurant)
  })
}

export function* deleteDishes(action: any):any{
  const dishesId = action.payload.dishesId;
  const restaurant = yield select(reducers.getRestaurant);

  const response = yield call(deleteDishesFetch, dishesId, restaurant.id);
  yield call(norr.processing, response, function *(){
    yield put(actions.showWindow('editDishes', false))
    yield call(getDishesByRestaurant)
  })
}

export function* deleteCategoryNational(action: any): any{
  const categoryId = action.payload.categoryId;
  const cb = action.payload.callback;

  const response = yield call(deleteCategoryNationalFetch, categoryId);
  yield call(norr.processing, response, function *(){
    yield call(getKitchens)
    cb()
  })
}

export function* deleteCategoryDishes(action: any) : any{
  const categoryId = action.payload.categoryId;
  const cb = action.payload.callback;

  const response = yield call(deleteCategoryDishesFetch, categoryId);
  yield call(norr.processing, response, function *(){
    yield call(getCategoriesDishes)
    cb()
  })
}

export function* uploadXls(action: any): any {
  yield put(actions.reqRestaurants(true))

  const file = action.payload.file;

  const response = yield call(uploadXlsFetch, file);
  yield call(norr.processing, response, function *(){
    yield call(getRestaurants)
  })

  yield put(actions.reqRestaurants(false))
}

export function* uploadXlsDish(action: any): any {
  yield put(actions.reqRestaurants(true))

  const file = action.payload.file;

  const response = yield call(uploadXlsDishFetch, file);
  yield call(norr.processing, response, function *(){})

  yield put(actions.reqRestaurants(false))
}

export function* updateAverageReceipt(action: any): any {
  yield put(actions.reqRestaurants(true))

  const restaurant = yield select(reducers.getRestaurant);

  const response = yield call(getAverageReceiptFetch, restaurant.id);
  yield call(norr.processing, response, function *(){
    yield put(actions.setCurrentRestaurant({...restaurant, addInfo: {...restaurant.addInfo, averageReceipt: response.value}}))
  })

  yield put(actions.reqRestaurants(false))
}

export function* setFullness(action: any): any{
  yield put(actions.reqRestaurants(true))
  const { id, isFullness } = action.payload;

  const response = yield call(updateFullnessFetch, id, { isFullness } );
  yield call(norr.processing, response, function *(){
    yield call(getRestaurants)
  })
  
  yield put(actions.reqRestaurants(false))
}

export function* watch() {
  yield takeEvery(constants.RESTAURANT_SAGA_GET, getRestaurants)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_BY_ID, getRestaurantById)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_MANAGER_BY_RESTAURANT, getManagerByRestaurant)
  yield takeEvery(constants.RESTAURANT_SAGA_CURRENT_IS_SHOW_WINDOW, showCurrentWin)
  yield takeEvery(constants.RESTAURANT_SAGA_POST, postRestaurant)
  yield takeEvery(constants.RESTAURANT_SAGA_UPLOAD_XLS, uploadXls)
  yield takeEvery(constants.RESTAURANT_SAGA_UPLOAD_XLS_DISHES, uploadXlsDish)
  yield takeEvery(constants.RESTAURANT_SAGA_UPDATE_AVERAGE, updateAverageReceipt)
  yield takeEvery(constants.RESTAURANT_SAGA_PUT, putRestaurant)
  yield takeEvery(constants.RESTAURANT_SAGA_DELETE, deleteRestaurant)
  yield takeEvery(constants.RESTAURANT_SAGA_BIND_OWNER, bindOwner)
  yield takeEvery(constants.RESTAURANT_SAGA_SET_ACTIVE, setActiveRestaurant)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_BIDS, getBidsMy)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_BIDS_HELPS, getBidsHelps)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_ALL_UPDATE, getBidsUpdate)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_BID_UPDATE_BY_ID, getBidUpdateById)
  yield takeEvery(constants.RESTAURANT_SAGA_COMPLETE_BID, completeBid)
  yield takeEvery(constants.RESTAURANT_SAGA_DENY_BID, denyBid)
  yield takeEvery(constants.RESTAURANT_SAGA_COMPLETE_BID_UPDATE, completeBidUpdate)
  yield takeEvery(constants.RESTAURANT_SAGA_DENY_BID_UPDATE, denyBidUpdate)
  yield takeEvery(constants.RESTAURANT_SAGA_DELETE_BID, deleteBid)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_CATEGORIES_DISHES, getCategoriesDishes)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_CATEGORIES_HOOKAH, getCategoriesHookah)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_TAGS_DISHES, getTagsDishes)
  yield takeEvery(constants.RESTAURANT_SAGA_POST_CATEGORIES_DISHES, postCategoriesDishes)
  yield takeEvery(constants.RESTAURANT_SAGA_POST_CATEGORIES_HOOKAH, postCategoriesHookah)
  yield takeEvery(constants.RESTAURANT_SAGA_POST_YOUTUBE, postYoutubeVideo)
  yield takeEvery(constants.RESTAURANT_SAGA_POST_RUTUBE, postRutubeVideo)
  yield takeEvery(constants.RESTAURANT_SAGA_POST_VIDEO, postVideo)
  yield takeEvery(constants.RESTAURANT_SAGA_DELETE_VIDEO_RUTUBE, deleteRutubeVideo)
  yield takeEvery(constants.RESTAURANT_SAGA_DELETE_VIDEO, deleteVideo)
  yield takeEvery(constants.RESTAURANT_SAGA_PUT_CATEGORIES_DISHES, putCategoriesDishes)
  yield takeEvery(constants.RESTAURANT_SAGA_PUT_CATEGORIES_HOOKAH, putCategoriesHookah)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_CATEGORIES, getCategories)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_KITCHENS, getKitchens)
  yield takeEvery(constants.RESTAURANT_SAGA_SET_ORDERS_DISHES, setOrderDishes)
  yield takeEvery(constants.RESTAURANT_SAGA_SET_ORDERS_HOOKAH, setOrderHookah)
  yield takeEvery(constants.RESTAURANT_SAGA_GET_DISHES_BY_RESTAURANT_ID, getDishesByRestaurant)
  yield takeEvery(constants.RESTAURANT_SAGA_POST_KITCHENS, postKitchens)
  yield takeEvery(constants.RESTAURANT_SAGA_PUT_KITCHENS, putKitchens)
  yield takeEvery(constants.RESTAURANT_SAGA_POST_DISHES, postDishes)
  yield takeEvery(constants.RESTAURANT_SAGA_COPY_DISHES, copyDishes)
  yield takeEvery(constants.RESTAURANT_SAGA_PUT_DISHES, putDishes)
  yield takeEvery(constants.RESTAURANT_SAGA_POST_HOOKAH, postHookah)
  yield takeEvery(constants.RESTAURANT_SAGA_PUT_HOOKAH, putHookah)
  yield takeEvery(constants.RESTAURANT_SAGA_DELETE_DISHES, deleteDishes)
  yield takeEvery(constants.RESTAURANT_SAGA_DELETE_CATEGORY_DISHES, deleteCategoryDishes)
  yield takeEvery(constants.RESTAURANT_SAGA_DELETE_CATEGORY_NATIONAL, deleteCategoryNational )
  yield takeEvery(constants.RESTAURANT_SAGA_DELETE_HOOKAH, deleteHookah)
  yield takeEvery(constants.RESTAURANT_SAGA_POST_COORDINATE, setCoordinate)
  yield takeEvery(constants.RESTAURANT_SAGA_SET_FULLNESS, setFullness)
}

export default function* sagas() {
  yield fork(watch)
}
